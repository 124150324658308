import { Menu, MinusCircle } from "iconoir-react";
import { FormElementV2ResponseDto, KnowledgeBase, PriorityType, StorageType } from "src/backend";
import { Type } from "src/constants/form-element";
import { MenuIcon } from "src/icons/menu-icon";
import { NotAllowedIcon } from "src/icons/not-allowed-icon";
import { OneArrowDownIcon } from "src/icons/one-arrow-down-icon";
import { OneArrowUpIcon } from "src/icons/onw-arrow-up-icon";
import { TwoArrowsDownIcon } from "src/icons/two-arrows-down-icon";
import { TwoArrowsUpIcon } from "src/icons/two-arrows-up-icon";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import type { FormElement } from "src/types/view";

// find form elements with answer recursively
export const getAnsweredFormElementsAsList = (formElement: FormElement, answeredFormElements: FormElement[] = []): FormElement[] => {
    if (formElement.answer) {
        answeredFormElements.push(formElement);
    }
    if (formElement.children) {
        formElement.children.forEach(child => {
            getAnsweredFormElementsAsList(child, answeredFormElements);
        });
    }
    return answeredFormElements;
};

// a function that checks if a form element children have children answer of a specific type
// recursively
export const hasChildrenWithFilter = (formElement: FormElementV2ResponseDtoExtended, allElements: FormElementV2ResponseDtoExtended[], callback: (element: FormElementV2ResponseDtoExtended) => boolean): boolean => {
    const children = allElements.filter(element => formElement.childrenIds?.includes(element.id));
    return children.some(child => {
        if (child.storageType === StorageType.FOLDER) {
            return hasChildrenWithFilter(child, allElements, callback);
        }
        return callback(child);
    });
}


// get all children ids recursively for a form element
export const getChildrenIds = (formElement: FormElementV2ResponseDto, allFormElements: FormElementV2ResponseDto[], includeVirtualFolders: boolean = false, childrenIds: string[] = []): string[] => {
    if (formElement.childrenIds) {
        const children = allFormElements.filter(element => formElement.childrenIds?.includes(element.id));
        children.forEach(child => {
            if (child.knowledgeBase !== KnowledgeBase.VIRTUAL_FOLDER || includeVirtualFolders) {
                childrenIds.push(child.id);
            }
            getChildrenIds(child, allFormElements, includeVirtualFolders, childrenIds);
        });
    }
    return childrenIds;
};

// get a FormElement and flatten it
export const flattenFormElement = (formElement: FormElement, parentTitle: string = '', flattenedFormElements: FormElement[] = []): FormElement[] => {
    flattenedFormElements.push({
        ...formElement,
        children: [],
        parent: {
            id: formElement?.parent?.id,
            title: parentTitle ?? '',
            knowledgeBase: formElement?.parent?.knowledgeBase
        }
    });
    if (formElement?.children && formElement.knowledgeBase !== KnowledgeBase.SHOE_BOX) {
        formElement.children.forEach(child => {
            flattenFormElement(child, formElement.title, flattenedFormElements);
        });
    }
    return flattenedFormElements;
};

export const flattenFullFormElement = (formElement: FormElement, parentTitle: string = '', flattenedFormElements: FormElement[] = []): FormElement[] => {
    flattenedFormElements.push({
        ...formElement,
        children: [],
        parent: {
            id: formElement?.parent?.id,
            title: parentTitle ?? '',
            knowledgeBase: formElement?.parent?.knowledgeBase
        }
    });
    if (formElement?.children) {
        formElement.children.forEach(child => {
            flattenFullFormElement(child, formElement.title, flattenedFormElements);
        });
    }
    return flattenedFormElements;
};


// find form element by knowLedgeBase recursively
export const findFormElementByKnowledgeBase = (formElements: FormElement[], knowledgeBase: KnowledgeBase): FormElement | undefined => {
    if (!formElements || !knowledgeBase) {
        return undefined;
    }
    for (const formElement of formElements) {
        if (formElement.knowledgeBase === knowledgeBase) {
            return formElement;
        }
        if (formElement.storageType === Type.SECTION) {
            const result = findFormElementByKnowledgeBase(formElement.children, knowledgeBase);
            if (result) {
                return result;
            }
        }
    }
    return undefined;
};

// find section form elements that has file form elements that belongs to a user
export const findUserSectionsWithFiles = (rootElement: FormElement, userId: string): FormElement[] => {
    if (!rootElement) {
        return [];
    }
    const userSections: FormElement[] = [];
    const findUserSections = (element: FormElement): void => {
        if (element.storageType === Type.SECTION) {
            if (!element.invisible &&
                element.children.some(child => child.storageType === Type.FILE && child.assignedToUser.id === userId)) {
                userSections.push(element);
            }
            element.children.forEach((child: FormElement) => {
                findUserSections(child);
            }
            );
        }
    }
    findUserSections(rootElement);
    return userSections;
}

export const hideFormElementFromUIFilter = (element: FormElement): boolean => {
    return !element.invisible;
}

// get ids of provided form element and it's children recursively
export const getFormElementIds = (element: FormElement): string[] => {
    const ids: string[] = [];
    const getIds = (element: FormElement): void => {
        ids.push(element.id);
        element.children.filter(child => !child.invisible).forEach((child: FormElement) => {
            getIds(child);
        }
        );
    }
    getIds(element);
    return ids;
}

// get ids of provided form element and it's children recursively
export const getFormElementChildren = (element: FormElement): FormElement[] => {
    const formElements: FormElement[] = [];
    const getIds = (element: FormElement): void => {
        formElements.push(element);
        element.children.filter(child => !child.invisible).forEach((child: FormElement) => {
            getIds(child);
        }
        );
    }
    getIds(element);
    return formElements;
}

export const getFormElementPriorityColor = (priority: PriorityType) => {
    let color: "primary" | "secondary" | "success" | "error" | "info" | "warning" | "default" = "default";

    switch (priority) {
        case PriorityType.HIGH:
        case PriorityType.BLOCKER:
        case PriorityType.CRITICAL:
            color = "error";
            break;
        case "MEDIUM":
        case "PRIVATE":
            color = "warning";
            break;
        case "LOW":
            color = "primary";
            break;
        case "HIDDEN":
            color = "primary";
            break;
        case PriorityType.Unset:
        case PriorityType.IGNORE:
            color = "info";
            break;
    }

    return color
}

export const getFormElementPriorityLabel = (priority: PriorityType) => {
    if (priority === PriorityType.Unset) {
        return "None"
    }

    return priority.toLowerCase();
}

// hide priority Hidden, Highest and Private
export const filterPriority = (priority: PriorityType) => {
    if (priority === PriorityType.HIDDEN ||
        priority === PriorityType.URGENT ||
        priority === PriorityType.UNSET ||
        priority === PriorityType.PRIVATE) {
        return false;
    }
    return true;
}

export const sortPriorities = (a: PriorityType, b: PriorityType) => {
    // high and urgent should be at the top
    // then medium 
    // then private
    // then low 
    // then hidden
    if (a === PriorityType.BLOCKER) {
        return -1;
    }
    if (b === PriorityType.BLOCKER) {
        return 1;
    }
    if (a === PriorityType.CRITICAL) {
        return -1;
    }
    if (b === PriorityType.CRITICAL) {
        return 1;
    }
    if (a === PriorityType.HIGH) {
        return -1;
    }
    if (b === PriorityType.HIGH) {
        return 1;
    }
    if (a === PriorityType.MEDIUM) {
        return -1;
    }
    if (b === PriorityType.MEDIUM) {
        return 1;
    }
    if (a === PriorityType.LOW) {
        return -1;
    }
    if (b === PriorityType.LOW) {
        return 1;
    }
    if (a === PriorityType.IGNORE) {
        return -1;
    }
    if (b === PriorityType.IGNORE) {
        return 1;
    }
    return 0;

}

export const getPriorityIcon = (priority: PriorityType): React.ReactNode => {
    switch (priority) {
        case PriorityType.URGENT:
        case PriorityType.CRITICAL:
            return <TwoArrowsUpIcon />;
        case "HIGH":
            return <OneArrowUpIcon />;
        case "MEDIUM":
            return <MenuIcon />;
        case "PRIVATE":
            return <MenuIcon />
        case "LOW":
            return <OneArrowDownIcon />;
        case "HIDDEN":
            return <TwoArrowsDownIcon />;
        case PriorityType.Unset:
            return <Menu width={20} height={20} fr="" />;
        case PriorityType.BLOCKER:
            return <MinusCircle width={20} height={20} fr="" />;
        default:
            return <NotAllowedIcon />;
    }
}
