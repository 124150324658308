import { ElementStatusType, FormElementV2ResponseDto } from "src/backend";

// Borrower can only view documents in status other than: OPEN, IN_PROGRESS, SUBMITTED and REJECTED

export const isFormElementLocked = (formElement: FormElementV2ResponseDto, isBorrower: boolean): boolean => {
    return (isBorrower &&
        ![
            ElementStatusType.OPEN,
            ElementStatusType.IN_PROGRESS,
            ElementStatusType.SUBMITTED,
            ElementStatusType.REJECTED
        ].includes(formElement.status));
}