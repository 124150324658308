import Breadcrumb from "components/dist/atoms/Breadcrumb";
import Button from "components/dist/atoms/Button"
import Dialog from "components/dist/atoms/Dialog"
import Icon from "components/dist/atoms/Icon"
import Separator from "components/dist/atoms/Separator";
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import Tooltip from "components/dist/atoms/Tooltip"
import ScreenSizeDropdownMenu from "components/dist/molecules/ScreenSizeDropdownMenu";
import { Fragment } from "react";
import { Virtuoso } from "react-virtuoso";

import { useCopyMoveToDialogState } from "./copy-move-to-dialog.state"
import { ICopyMoveToDialog } from "./copy-move-to-dialog.types"
import { CopyMoveToDialogBreadcrumbItem } from "./copy-move-to-dialog-breadcrumb-item.component";
import { CopyMoveToDialogListItem } from "./copy-move-to-dialog-list-item.component";

export const CopyMoveToDialog = (props: ICopyMoveToDialog['props']) => {
    const state = useCopyMoveToDialogState(props);

    return (<Dialog
        onOpenChange={state.onDialogOpenChange}
        open={props.elements.length > 0}>
        <Dialog.Content
            onOpenAutoFocus={event => event.preventDefault()}
            className="w-full h-full sm:h-auto sm:max-w-2xl gap-0 sm:gap-0">
            <Stack className="sm:hidden p-3" items="start">
                <Dialog.Close asChild>
                    <Button circle variant="secondary" size="sm" className="p-0 w-6 h-6">
                        <Icon name="Cancel" width={16} height={16} strokeWidth={1.5} />
                    </Button>
                </Dialog.Close>
            </Stack>
            <Dialog.Title className="pt-3 pb-2">
                {state.title}
            </Dialog.Title>
            <Separator />
            <Breadcrumb className="bg-black-10 px-4">
                <Breadcrumb.List>
                    {state.collapsedBreadcrumbs.length > 0 && <Fragment>
                        <Breadcrumb.Separator />
                        <ScreenSizeDropdownMenu
                            modal={false}>
                            <ScreenSizeDropdownMenu.Trigger>
                                <Breadcrumb.Ellipsis className="h-4 w-4" />

                            </ScreenSizeDropdownMenu.Trigger>
                            <ScreenSizeDropdownMenu.Content
                                className="p-2 w-48 max-w-full"
                                align="start"
                                side="bottom"
                                withPortal={false}
                            >
                                {state.collapsedBreadcrumbs.map(element => <ScreenSizeDropdownMenu.Item
                                    key={element.id}
                                    onSelect={() => state.onElementClick(element)}
                                >
                                    {element.title}
                                </ScreenSizeDropdownMenu.Item>)}
                            </ScreenSizeDropdownMenu.Content>
                        </ScreenSizeDropdownMenu>
                    </Fragment>}
                    {state.breadcrumbs.map(element => <Fragment key={element.id}>
                        <Breadcrumb.Separator />
                        <CopyMoveToDialogBreadcrumbItem
                            isActive={element.id === state.activeFolderElementId}
                            element={element}
                            onClick={(element) => state.onElementClick(element)}
                        />
                    </Fragment>)}
                </Breadcrumb.List>
            </Breadcrumb>
            <Stack
                space="xs"
                className="p-4 divide-y divide-gray-neutral-80 h-full sm:h-[426px] max-h-full overflow-y-hidden" >
                {state.list.length > 1 && <Virtuoso
                    className="scrollbar-stable"
                    fixedItemHeight={52}
                    totalCount={state.list.length}
                    itemContent={(index) => {
                        const element = state.list[index];
                        return <CopyMoveToDialogListItem
                            key={element.id}
                            operation={props.operation}
                            loggedInUserId={state.loggedInUserId}
                            element={element}
                            onCopyHereClick={state.onCopyHereClick}
                            isDisabled={state.isElementDisabled(element)}
                            isActive={element.id === state.activeElement?.id}
                            onClick={(element) => state.onElementClick(element)}
                        />
                    }}
                />}
                {state.list.length === 0 && <Stack
                    space="md"
                    items="center"
                    justify="center" className="flex-1">
                    <Icon name="FolderFindEmpty" />
                    <Text size="sm">
                        No item in this folder. {props.operation === "COPY" ? "Copy" : "Move"} to add items
                    </Text>
                </Stack>}
            </Stack>
            <Dialog.Footer className="gap-3 flex-row justify-between sm:justify-between">
                <Button
                    onClick={state.onNewFolderClick}
                    disabled={props.isLoading} variant="outline" className="text-blue-100 gap-2">
                    <Icon name="FolderPlus" width={21} height={19} /> New Folder
                </Button>
                <Stack row space="sm" items="center">
                    <Dialog.Close asChild className="hidden sm:inline-flex">
                        <Button
                            disabled={props.isLoading}
                            onClick={state.onCancelClick}
                            variant="outline">
                            Cancel
                        </Button>
                    </Dialog.Close>
                    <Tooltip.Provider>
                        <Tooltip delayDuration={1000}>
                            <Tooltip.Trigger asChild>
                                <Button
                                    className="w-48 max-w-full truncate inline text-center"
                                    loading={props.isLoading}
                                    onClick={state.onConfirmClick}>
                                    {state.actionText}
                                </Button>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {state.actionText}
                            </Tooltip.Content>
                        </Tooltip>
                    </Tooltip.Provider>
                </Stack>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog>)
}